import React, { useEffect, useState } from "react";
import {
  Theme,
  Card,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import useReactRouter from "use-react-router";
import { useStore } from "../../stores";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "fixed",
      height: "100%",
      width: "100%",
      backgroundColor: "#7C7C7C50",
      "& *": {
        fontFamily: "Montserrat, sans-serif",
      },
    },
    container2: {
      display: "flex",
      minHeight: 600,
      height: "100%",
    },
    card: {
      position: "relative",
      display: "flex",
      boxSizing: "border-box",
      justifyContent: "center",
      flexDirection: "column",
      padding: "40px 60px",
      minWidth: 472,
      borderRadius: 0,
      boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.10)",
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
        width: "100%",
        padding: "10px 20px",
      },
    },
    logo: {
      position: "absolute",
      top: "40px",
      left: "60px",
      [theme.breakpoints.down("xs")]: {
        top: "10px",
        left: "20px",
      },
    },
    logoImg: {
      padding: "0 0 0 0",
      margin: "0 0 0 0",
      maxHeight: 100,
      maxWidth: 200,
    },
    header: {
      fontSize: "48px",
      lineHeight: "56px",
      fontWeight: 700,
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        lineHeight: "36px",
      },
    },
    div: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
    form: {
      padding: "0 0 0 0",
    },
    inner: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: 0,
      "&:last-child": {
        padding: 0,
      },
    },
    textField: {
      width: 352,
      margin: 0,
      "& .MuiFormLabel-root": {
        position: "static",
        transform: "none",
        marginBottom: "4px",
        color: "#1A1A1A",
        "& .MuiFormLabel-asterisk": {
          display: "none",
        },
      },
      "& .MuiInputBase-root, &.MuiInputBase-root": {
        margin: 0,
        "&::before, &::after": {
          content: "none",
        },
      },
      "& .MuiInputBase-input": {
        padding: "12px 8px",
        fontSize: "14px",
        lineHeight: "20px",
        border: "1px solid #666",
        borderRadius: "6px",
        "&:focus": {
          borderColor: "#22A5A6",
        },
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    error: {
      fontSize: "12px",
      color: "red",
      marginLeft: 0,
    },
    divButton: {
      padding: 0,
      width: "100%",
      position: "relative",
    },
    button: {
      width: "100%",
      margin: "8px 0",
      padding: "14px 24px",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 600,
      color: "#fff",
      textTransform: "none",
      background: "#22A5A6",
      borderRadius: "6px",
      boxShadow: "none",
      "&:hover": {
        background: "#499596",
      },
      "&:active": {
        background: "#3D7B7D",
      },
      "&:disabled": {
        background: "#ECECEC",
        color: "#ccc",
      },
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -20,
    },
    description: {
      maxWidth: "352px",
      paddingTop: "16px",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 600,
      borderTop: "1px solid #EAEAF3",
    },
    passwordWrap: {
      position: "relative",
    },
    iconWrap: {
      position: "absolute",
      top: "34px",
      right: "8px",
      cursor: "pointer",
      "& svg": {
        width: "16px",
        height: "16px",
        fill: "#7C7C7C",
      },
      "& svg.show": {
        fill: "#22A5A6",
      },
    },
  })
);

const next = (history: any) => {
  const cookie: string = document.cookie;
  const index: number = cookie.indexOf("next=");
  if (index >= 0) {
    const indexEnd: number = cookie.indexOf(";", index + 1);
    let nextStr: string =
      indexEnd > index
        ? cookie.substring(index + 5, indexEnd)
        : cookie.substring(index + 5);
    // const upCookie: string = cookie
    //   .replace(`next=${nextStr};`, "")
    //   .replace(";", "")
    //   .trim();
    document.cookie = "next=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    window.location.assign(nextStr);
  } else {
    history.push("/");
  }
};

const inputRegEx = /[\s]/g;

const LoginPage: React.FC = observer(() => {
  const store = useStore();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const [state, setState] = useState({
    errorText: "",
    processing: false,
    initiated: false,
    domain: "",
    login: "",
    pwd: "",
    switchOn: false,
  });
  const { history } = useReactRouter();
  const logo = "/rest/v1/public/resources/logo";

  const sanitize = (value: string) => {
    return value.replace(inputRegEx, "");
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    setState({ ...state, processing: true, errorText: "" });
    if (store) {
      if (store.ssoEnabled) {
        store
          .checkSSOProviders(state.domain)
          .then((res) => {})
          .finally(() => {
            setState({ ...state, processing: false });
          });
      } else {
        store.login(state, state.switchOn).then(function (value) {
          if (value) {
            store.setAuthorized(true);
            next(history);
          } else setState({ ...state, processing: false, errorText: store.errorText });
        });
      }
    }
  };

  const toggleChecked = () => {
    const changeSwitch = !state.switchOn;
    setState({ ...state, switchOn: changeSwitch });
  };

  const onProviderSelect = (rawProvider?: string) => {
    try {
      if (!rawProvider) {
        setState({ ...state, errorText: "Ошибка обработки данных провайдера" });
        return;
      }
      const provider = JSON.parse(rawProvider as string);
      if (provider.sso_url) {
        window.location.href = provider.sso_url;
      }
    } catch (err) {
      setState({ ...state, errorText: "Ошибка обработки данных провайдера" });
    }
  };

  useEffect(() => {
    if (store) {
      store
        .checkSSOSetting()
        .then((ssoEnabled) => {})
        .finally(() => {
          setState({ ...state, initiated: true });
        });
    }
  }, [store]);

  const isUnavailable = !store || (store && store.ssoEnabled === null);

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.container2}>
          <Card className={classes.card}>
            <div className={classes.logo}>
              <img src={logo} alt="l" className={classes.logoImg} />
            </div>
            <div className={classes.div}>
              <div className={classes.header}>Войти</div>

              {state.initiated ? (
                <React.Fragment>
                  {isUnavailable ? (
                    <div style={{ color: "red" }}>
                      Сервис временно недоступен
                    </div>
                  ) : (
                    <form className={classes.form} onSubmit={submitHandler}>
                      <CardContent className={classes.inner}>
                        <TextField
                          id="domain"
                          label="Домен"
                          value={state.domain}
                          className={classes.textField}
                          margin="normal"
                          onChange={(e) =>
                            setState({
                              ...state,
                              domain: sanitize(e.target.value),
                              errorText: "",
                            })
                          }
                        />

                        {store &&
                        store.ssoEnabled &&
                        store.ssoProviders.length ? (
                          <div>
                            <div style={{ margin: "3px 0" }}>
                              Провайдер авторизации
                            </div>
                            <Select
                              className={classes.textField}
                              value=""
                              MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                              }}
                              onChange={(e) =>
                                onProviderSelect(e.target.value as string)
                              }
                            >
                              {store.ssoProviders.map((provider) => (
                                <MenuItem
                                  key={provider.sso_stateid}
                                  value={JSON.stringify(provider)}
                                >
                                  {provider.sso_type}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        ) : null}
                        {store ? (
                          <React.Fragment>
                            {store.ssoEnabled ? null : (
                              <React.Fragment>
                                <TextField
                                  required
                                  id="login"
                                  label="Логин"
                                  value={state.login}
                                  className={classes.textField}
                                  margin="normal"
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      login: sanitize(e.target.value),
                                      errorText: "",
                                    })
                                  }
                                />
                                <div className={classes.passwordWrap}>
                                  <TextField
                                    required
                                    id="pwd"
                                    label="Пароль"
                                    value={state.pwd}
                                    className={classes.textField}
                                    type={showPassword ? "text" : "password"}
                                    margin="normal"
                                    onChange={(e) =>
                                      setState({
                                        ...state,
                                        pwd: sanitize(e.target.value),
                                        errorText: "",
                                      })
                                    }
                                  />
                                  <div
                                    className={classes.iconWrap}
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <svg
                                        className={`${
                                          showPassword ? "show" : ""
                                        }`}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M4.44445 7.99908C4.44445 6.03519 6.03611 4.44352 8 4.44352C9.96389 4.44352 11.5556 6.03519 11.5556 7.99908C11.5556 9.96297 9.96389 11.5546 8 11.5546C6.03611 11.5546 4.44445 9.96297 4.44445 7.99908ZM8 10.2213C9.22778 10.2213 10.2222 9.22686 10.2222 7.99908C10.2222 6.7713 9.22778 5.77686 8 5.77686C7.98056 5.77686 7.96389 5.77686 7.91945 5.77686C7.98056 5.91852 8 6.06852 8 6.2213C8 7.20186 7.20278 7.99908 6.22222 7.99908C6.06945 7.99908 5.91945 7.97963 5.77778 7.91852C5.77778 7.96297 5.77778 7.97963 5.77778 7.97408C5.77778 9.22686 6.77222 10.2213 8 10.2213ZM2.65056 4.01574C3.95834 2.80019 5.75556 1.77686 8 1.77686C10.2444 1.77686 12.0417 2.80019 13.35 4.01574C14.65 5.2213 15.5194 6.64074 15.9306 7.65741C16.0222 7.87685 16.0222 8.1213 15.9306 8.34074C15.5194 9.33241 14.65 10.7519 13.35 11.9824C12.0417 13.1991 10.2444 14.2213 8 14.2213C5.75556 14.2213 3.95834 13.1991 2.65056 11.9824C1.35056 10.7519 0.48167 9.33241 0.0683646 8.34074C-0.0227882 8.1213 -0.0227882 7.87685 0.0683646 7.65741C0.48167 6.64074 1.35056 5.2213 2.65056 4.01574ZM8 3.11019C6.18889 3.11019 4.7 3.93241 3.55834 4.99074C2.48889 5.98519 1.75056 7.14074 1.37334 7.99908C1.75056 8.83241 2.48889 10.013 3.55834 11.0074C4.7 12.0657 6.18889 12.888 8 12.888C9.81111 12.888 11.3 12.0657 12.4417 11.0074C13.5111 10.013 14.225 8.83241 14.6278 7.99908C14.225 7.14074 13.5111 5.98519 12.4417 4.99074C11.3 3.93241 9.81111 3.11019 8 3.11019Z" />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                      >
                                        <g clipPath="url(#clip0)">
                                          <g clipPath="url(#clip1)">
                                            <path
                                              d="M10 11.9444C13.4708 11.9444 16.5877 10.934 18.7248 9.33033C18.7415 9.55136 18.75 9.77469 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 9.77469 1.25852 9.55136 1.27524 9.33033C3.41234 10.934 6.52917 11.9444 10 11.9444ZM10 16.8056C12.7414 16.8056 15.1043 15.1846 16.1824 12.8488C14.2976 13.5253 12.1869 13.8889 10 13.8889C7.81312 13.8889 5.70236 13.5253 3.8176 12.8488C4.8957 15.1846 7.25855 16.8056 10 16.8056ZM7.27012 12.9737C7.14939 12.6536 12.8659 12.5677 12.7721 12.8538C12.39 14.0194 11.2933 14.8611 10 14.8611C8.75147 14.8611 7.68617 14.0766 7.27012 12.9737ZM10 1.25C12.8625 1.25 15.404 2.62455 17.0003 4.7496C15.0504 3.77309 12.6266 3.19444 10 3.19444C7.37343 3.19444 4.94959 3.77309 2.99966 4.7496C4.59604 2.62455 7.1375 1.25 10 1.25Z"
                                              fill="#717781"
                                            ></path>
                                          </g>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0">
                                            <rect
                                              width="17.5"
                                              height="17.5"
                                              fill="white"
                                              transform="translate(1.25 1.25)"
                                            ></rect>
                                          </clipPath>
                                          <clipPath id="clip1">
                                            <rect
                                              width="17.5"
                                              height="17.5"
                                              fill="white"
                                              transform="translate(1.25 1.25)"
                                            ></rect>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    )}
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ) : null}

                        {state.errorText ? (
                          <div style={{ padding: 9, height: 24 }}>
                            <p className={classes.error}>{state.errorText}</p>
                          </div>
                        ) : null}
                        <div className={classes.divButton}>
                          {store ? (
                            <React.Fragment>
                              {store.ssoEnabled ? (
                                <Button
                                  variant="contained"
                                  disabled={state.processing}
                                  className={classes.button}
                                  type="submit"
                                >
                                  Далее
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  disabled={state.processing}
                                  className={classes.button}
                                  type="submit"
                                >
                                  Войти
                                </Button>
                              )}
                            </React.Fragment>
                          ) : null}

                          {state.processing && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </div>
                        {state.errorText.includes("1410") && (
                          <div style={{ height: 24 }}>
                            {
                              <FormControlLabel
                                className={classes.textField}
                                control={
                                  <Switch
                                    color="primary"
                                    checked={state.switchOn}
                                    onChange={toggleChecked}
                                  />
                                }
                                label="предварительно закрыв все сессии"
                              />
                            }
                          </div>
                        )}
                        <div className={classes.description}>
                          Для восстановления доступа к учетной записи обратитесь
                          к администратору.
                        </div>
                      </CardContent>
                    </form>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <CircularProgress color="secondary" />
                </React.Fragment>
              )}
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
});

export default LoginPage;
